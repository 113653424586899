<template>
  <div style="padding-block-end: 60px; padding-left: 50px">
    <div class="box-body-title">模型基本信息</div>
    <el-row class="box-body-text">
      <el-col :span="3" class="box-body-text-col">模型基本信息</el-col>
      <el-col :span="8"><el-input></el-input></el-col>
    </el-row>
    <el-row class="box-body-text">
      <el-col :span="3" class="box-body-text-col">模型描述</el-col>
      <el-col :span="8"><el-input type="textarea"></el-input></el-col>
    </el-row>
    <div class="box-body-title">数据预算法配置</div>
    <el-row class="box-body-text">
      <el-col :span="3" class="box-body-text-col">实验数据集</el-col>
      <el-col :span="8" style="display: flex">
        <el-select v-model="selectSet" placeholder="数据集">
          <el-option label="数据集1" value="1"></el-option>
          <el-option label="数据集2" value="2"></el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-row class="box-body-text">
      <el-col :span="3" class="box-body-text-col">试验集拆分</el-col>
      <el-col :span="8" style="display: flex">
        <div style="width: 100%">
          <div style="display: flex; justify-content: space-between">
            <span>训练集</span>
            <span>测试集</span>
          </div>
          <el-slider v-model="sliderValue"></el-slider>
        </div>
      </el-col>
    </el-row>

    <el-row class="box-body-text">
      <el-col :span="3" class="box-body-text-col">算法类型</el-col>
      <el-col :span="8" style="display: flex">
        <el-select v-model="selectSet" placeholder="逻辑回归">
          <el-option label="逻辑回归" value="1"></el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-row class="box-body-text">
      <el-col :span="3" class="box-body-text-col">目标变量</el-col>
      <el-col :span="8" style="display: flex">
        <el-select v-model="selectSet" placeholder="Y">
          <el-option label="Y" value="Y"></el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-row class="box-body-text">
      <el-col :span="3" class="box-body-text-col">正例值</el-col>
      <el-col :span="8" style="display: flex">
        <el-select v-model="selectSet" placeholder="1">
          <el-option label="1" value="1"></el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-row class="box-body-text">
      <el-col :span="3" class="box-body-text-col">筛选模型特征</el-col>
      <el-col :span="8" style="display: flex">
        <el-button>列表选择</el-button>
        <el-button>输入编辑</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectSet: '',
      sliderValue: 0,
    };
  },
};
</script>

<style scoped>
.box-body-title {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  font-family: PingFang SC Regular;
}
.box-body-text {
  margin: 20px;
}
.box-body-text-col {
  margin-left: 50px;
  text-align: left;
}
</style>
